import React, { Component, Suspense } from "react";
import $ from "jquery";
import { apiCalling } from "../apiService";
import { Helmet } from "react-helmet";
import InnerHTML from "dangerously-set-html-content";
const folderName = window.sessionStorage.getItem("homeName");
const Header = React.lazy(() => import("../" + folderName + "/header"));
const Footer = React.lazy(() => import("../" + folderName + "/footer"));
export default class BookappointmentSuccess extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ratePerSession: "0.00",
      appointmentDate: "",
      doctorName: "",
      doctorEducation: "",
      userName: "",
      typeOfChat: "",
      hospitalName: "",
      hospitalCity: "",
      appointmentType: "",
      promocode:"",
      promocodeAmount:"",
      promocodePercentage:"",
      promomessage:"",
      applyPromocode:"",
    };

    this.confimAppointmentForNoPayment = this.confimAppointmentForNoPayment.bind(
      this
    );
    this.cancelAppointment = this.cancelAppointment.bind(this);
  }
  componentDidMount() {
    $(".loader").show();
    $("#promoApplySection").hide();
    $("#term-main").hide();
    const promoCodeData = JSON.parse(window.sessionStorage.getItem("promoCodeData"));
   //console.log(promoCodeData)
    $("#decline").click(function(){  
      $("#term-main").hide();
      $('#invalidCheck').prop('checked',false); 
     });

     $("#AcceptCall").click(function(){  
      $('#invalidCheck').prop('checked',true); 
      $("#term-main").hide();
     });
    if(promoCodeData.length===0){
      $("#havePromoCode").hide();
    }
    
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    document.body.appendChild(script);
    this.bindingBookAppointment();

    $(".popup-hide").click(function () {
      $(".popup-overlay").hide();
    });
    $("html").click(function () {
      $(".popup-overlay").hide();
    });

    $(".popup-content").click(function (event) {
      event.stopPropagation();
    });
  }

  termcondtn() {
    $("#term-main").show();
  }
  payNowAction() {
    if ($("#invalidCheck").is(":checked") === true) {
   
      let email = window.sessionStorage.getItem("emailId");
      let mobileNumber = window.sessionStorage.getItem("mobileNumber");
      let userName = parseFloat(window.sessionStorage.getItem("userName"));
      let amount = parseFloat(this.state.ratePerSession).toFixed(2) * 100;
      let options = {
        key: window.sessionStorage.getItem("razorKey"),
        amount: amount,
        name: window.sessionStorage.getItem("userName"),
        description: "",
        image: "",
        handler: function (response) {
          //console.log("Razorrr", response);
        
          const paymenTId = response.razorpay_payment_id;

          const razorpay = {
            method: "POST",
            body: JSON.stringify({
              functionName: "razorpay",
              paymentId: paymenTId,
              hospitalId: window.sessionStorage.getItem("appHosId"),
            }),
          };

          apiCalling(razorpay).then((dataa) => {
           // console.log("11111", dataa)
         
            var element = JSON.parse(window.sessionStorage.getItem("appArray"));
  
            
            element.promoCode=$("#promoCode_s").val();
            element.promoCodeAmount=$("#promocodeAmount").val()
            element.discountPercentage=$("#promocodePercentage").val()
            let newList = Object.assign(dataa.razorPayData, element);
            const apiJson = {
              method: "POST",
              body: JSON.stringify(newList),
            };
            //console.log(apiJson.body);
            $(".loader2").show();
            apiCalling(apiJson).then((data) => {
              window.sessionStorage.setItem(
                "appointmentSuccessArray",
                JSON.stringify(data)
              );
              window.sessionStorage.setItem(
                "appointmentSuccessArray2",
                JSON.stringify(apiJson.body)
              );
             
              if (data.success === "1") {
                $(".loader2").hide();
                window.sessionStorage.setItem("smsAndEmailFunction",JSON.stringify(data));
                window.location.href = "../patient/thankyou";
              } else {
                alert(data.errorMessage);
                window.sessionStorage.setItem("appTypeShow","");
                window.location.href = "../home/doctor-list-main/0";
              }
            });
          });

          // alert();
        },
        prefill: {
          name: userName,
          email: email,
          contact: mobileNumber,
        },
        // "notes": {
        //     "address":
        // },
        theme: {
          color: "#514886",
        },
      };

      let rzp = new window.Razorpay(options);
      rzp.open();
    } else {
      alert("Please agree with terms and conditions");
    }
  }
  havePromoCode() {
    $("#promoApplySection").show();
    $("#havePromoCode").hide();
  }
  applyPromocode() {
    var applyPromocodeAmt = this.state.applyPromocode;
    let promoCode=$("#promoCode").val();
    let discountPercentage="";
    if(promoCode!=""){
    const promoCodeData = JSON.parse(window.sessionStorage.getItem("promoCodeData"));
    promoCodeData.map((obj) => {
      if(obj.promoCode===promoCode){
        discountPercentage=obj.discountPercentage;
      }
    });
    if(discountPercentage!=""){
      alert("Promo code applied successfully")
      let ratePerSession=applyPromocodeAmt
      let amountNew=(ratePerSession*discountPercentage)/100;
      let amount=ratePerSession-((ratePerSession*discountPercentage)/100)   
      this.setState({ratePerSession:amount===0 ? "0.00" : amount});
      this.setState({promocode:promoCode});
      this.setState({promocodePercentage:discountPercentage});
      this.setState({promocodeAmount:amount});
      this.setState({promomessage:"You have saved Rs. "+amountNew.toFixed(2)+" on the bill "})
    $("#promoRemove").show();
    $("#applyPromocode").hide();
    } 
    else{
      alert("Invalid promo code !")
      //this.setState({ratePerSession:window.sessionStorage.getItem("messaging_cost")});
      this.setState({promomessage:""})
    }
  }
  else{
    alert("Please enter promo code properly ");
    $("#promoCode").focus();
  }
  }
  
  cancelAppointment() {
    window.location.href = "../home/doctor-list-main/1";
  }
  
  loginValidations() {
    if (!window.sessionStorage.getItem("isLogin")) {
      $("#ForgotPasswordWrap").hide();
      $("#SignupWrap").hide();
      $("#signInWrapDiv").show();
    }
  }
  confimAppointmentForNoPayment() {
    this.loginValidations();
    if ($("#invalidCheck").is(":checked") === true) {
      $(".loader2").show();
      let arryset = JSON.parse(window.sessionStorage.getItem("appArray"));
      const apiJson = {
        method: "POST",
        body: JSON.stringify(arryset),
      };
      //console.log(".....", apiJson.body);
      apiCalling(apiJson).then((data) => {
        window.sessionStorage.setItem(
          "appointmentSuccessArray",
          JSON.stringify(data)
        );
        window.sessionStorage.setItem(
          "appointmentSuccessArray2",
          JSON.stringify(apiJson.body)
        );
        $(".loader2").hide();
        if (data.success === "1") {
        window.sessionStorage.setItem("smsAndEmailFunction",JSON.stringify(data));
       // this.confirmVideoAppointmentMail(data);
          window.location.href = "../patient/thankyou";
        }
        // if (data.success === "1") {
        //   $(".loader").hide();
        //   alert(data.successMessage);
        //   window.location.href = "../home/doctor-list-main/0";
        // } 
        else {
          alert(data.errorMessage);
          
          window.location.href = "../patient/bookappointment";
        }
      });
    } else {
      alert("Please agree with terms and conditions");
    }
  }
  //Binding data
  bindingBookAppointment() {
    $(".loader").show();
    let arryset = JSON.parse(window.sessionStorage.getItem("appArray"));
    // console.log(arryset)
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "appointmentConfirmation",
        userId: window.sessionStorage.getItem("userId"),
        siteId: window.sessionStorage.getItem("siteId"),
        doctorId: arryset.doctorId,
        hospitalId: arryset.hospitalId,
        appointmentType: arryset.appointmentType,
        appointmentDate: arryset.appointmentDate,
        appointmentTime: arryset.appointmentTime,
        reasonForVisit: arryset.reasonForVisit,
        appointmentPerson: "",
        newUserDOB: arryset.dateOfBirth,
        newUserGender: arryset.gender,
        firstName: "",
        lastName: "",
        attachments: arryset.attachments,
        promoCode: arryset.promoCode,
        promoCodeAmount: arryset.promoCodeAmount,
        discountPercentage: arryset.discountPercentage,
        from: arryset.from,
      }),
    };
    $(".loader").show();
    // console.log(apiJson.body);
    apiCalling(apiJson).then((data) => {
      if (data.success === "1") {
        $(".loader").hide();
        var month = new Array();
        month[0] = "JAN";
        month[1] = "FEB";
        month[2] = "MARCH";
        month[3] = "APRIL";
        month[4] = "MAY";
        month[5] = "JUNE";
        month[6] = "JULY";
        month[7] = "AUG";
        month[8] = "SEP";
        month[9] = "OCT";
        month[10] = "NOV";
        month[11] = "DEC";
        var newAppDate = "";
        if (data.doctorAndAppointmentData[0].appointmentDate) {
          const a = data.doctorAndAppointmentData[0].appointmentDate.split("/");
          newAppDate = a[1] + "/" + a[0] + "/" + a[2];
        }
        var DateApp = new Date(newAppDate);
        var month = month[DateApp.getMonth()];
        var date = DateApp.getDate();
        var year = DateApp.getFullYear();
        var appDate = month + " " + date + " " + year;
       // alert(data.doctorAndAppointmentData[0].ratePerSession)
       // console.log(data)
        let sessionNo=parseFloat(data.doctorAndAppointmentData[0].ratePerSession.replace(/,/g, '')).toFixed(2)
      // alert(sessionNo)
        this.setState({
          applyPromocode:sessionNo,
          ratePerSession: sessionNo,
          appointmentDate:
            appDate + " " + data.doctorAndAppointmentData[0].appointmentTime,
          appointmentTime: data.doctorAndAppointmentData[0].appointmentTime,
          appointmentType: data.doctorAndAppointmentData[0].appointmentType,
          doctorEducation: data.doctorAndAppointmentData[0].doctorEducation,
          doctorId: data.doctorAndAppointmentData[0].doctorId,
          doctorName: data.doctorAndAppointmentData[0].doctorName,
          hospitalCity: data.doctorAndAppointmentData[0].hospitalCity,
          hospitalId: data.doctorAndAppointmentData[0].hospitalId,
          hospitalName: data.doctorAndAppointmentData[0].hospitalName,
          typeOfChat: data.doctorAndAppointmentData[0].typeOfChat,
          userId: data.doctorAndAppointmentData[0].userId,
          userName: data.doctorAndAppointmentData[0].userName,
        });
        window.sessionStorage.setItem("appTypeShow",data.doctorAndAppointmentData[0].appointmentType);
      } else {
        alert("Error : " + data.errorMessage);
      }
    });
  }
  removePromocode(){
    window.location.reload()
  }
  render() {
    return (
      <div class="purpleWrap">
           <Helmet>
          <title>{"Confirm Book An Appointment"}</title>
          <meta
            name="description"
            content="Confirm Book An Appointment"
          />
          {/* <meta name="Keywords" content="Cost of Dental Implants | Teeth Cleaning | Grinding teeth" /> */}
        </Helmet>
         <div class="loader2" ></div>
        <Suspense fallback={<div></div>}>
          <section>
            <Header />
          </section>
        </Suspense>
        <div class="container">
          <div class="main-wrapper-smwidth">
            <div class="form-section">
              <div class="col-sm-12 form-head p-0">
                Appointment Request Date &amp; Time :{" "}
                {this.state.appointmentDate}{" "}
              </div>

              <div class="col-md-12 mb-4 p-0 asdf">
                {this.state.doctorEducation ? (
                  <p class="font-bold mb-0">
                    {this.state.doctorName}, {this.state.doctorEducation}{" "}
                  </p>
                ) : (
                    <p class="font-bold mb-0">{this.state.doctorName} </p>
                  )}
                {this.state.hospitalCity ? (
                  <p class="font-16">
                    {this.state.hospitalName}, {this.state.hospitalCity}{" "}
                  </p>
                ) : (
                    <p class="font-16">{this.state.hospitalName} </p>
                  )}
              </div>

              <div class="col-md-12 mb-4 p-0">
                <p class="font-bold mb-0">
                  Patient Name : {this.state.userName}
                </p>
                <p class="mb-0 font-16">
                  Type Of Appointment : {this.state.appointmentType}
                </p>
              </div>

              <div
                class="col-sm-12 term-condtn-main p-0 mb-3"
                for="invalidCheck"
              >
                <label className="checkbox-main">
                  <input type="checkbox" id="invalidCheck" />
                  <span class="checkbox"></span>
                </label>
                I agree with{" "}
                <span
                  onClick={() => this.termcondtn()}
                  style={{ color: "#464646", cursor: "pointer" }}
                >
                  Terms and Conditions
                </span>
              </div>
              {this.state.appointmentType !="In Person" &&  this.state.ratePerSession !== "0.00" &&
              <div class="alert alert-success col-md-4 text-center cursor-pointer" id="havePromoCode">
                <label
                  class="m-0 cursor-pointer"
                  onClick={(e) => {
                    this.havePromoCode();
                  }}
                >
                  Have a Promo code?
                </label>
              </div>}
              {this.state.appointmentType !="In Person" &&  this.state.ratePerSession!="0.00" &&
                <div class="alert warnalert alert-danger col-md-12 mrgBottom alertScreen text-left smlNotification">
                 You will need to pay Rs.{Number(this.state.ratePerSession).toFixed(2)} to speak
                  with {this.state.doctorName}, {this.state.doctorEducation} at
                  {/* Rs.{Number(this.state.ratePerSession).toFixed(2)} /session on{" "} */}
                  {" "+this.state.hospitalName}.
                </div>}
              
              <div class="row row-margin promoCodeBox ml-1" id="promoApplySection">
                <label class="col-md-3 col-lg-3 pr-0 label-text pt-1">Promo code</label>
                <label class="col-sm-9 ">
                 <input type="text" class="input-design input-box-w-150" id="promoCode" style={{color:"black"}}/>  
                 <input type="hidden" class="input-design input-box-w-150" id="promoCode_s" defaultValue={this.state.promocode}/>                
                 <input type="hidden" class="input-design input-box-w-150" id="promocodeAmount" defaultValue={this.state.promocodeAmount}/>                
                 <input type="hidden" class="input-design input-box-w-150" id="promocodePercentage" defaultValue={this.state.promocodePercentage}/>                
                
                  <span class="formButton-apply cursor-pointer ml-2" id="applyPromocode" onClick={(e) => {
                    this.applyPromocode();
                  }}>Apply</span>
                  <span class="formButton-apply cursor-pointer ml-2" id="promoRemove" style={{display:"none"}} onClick={(e) => {
                    this.removePromocode();
                  }}>Remove</span><br></br>
                  <span id="promomessage"  className="promomessage">{this.state.promomessage} </span>
                </label>
                
              </div>
            </div>

            <div className="col-sm-12 text-left text-md-center p-0">
              {this.state.appointmentType !="In Person" && this.state.ratePerSession !== "0.00" ? (
                <input
                  type="submit"
                  value="Pay Now"

                  className="formButton formButtonBold"
                  onClick={(e) => {
                    this.payNowAction();
                  }}
                ></input>
              ) : (
                  <input
                    type="submit"
                    value="Send Request"
                    className="formButton formButtonBold"
                    onClick={this.confimAppointmentForNoPayment}
                  ></input>
                )}
              <input
                type="button"
                value="Cancel"
                className="formButton cancelButton  formButtonBold"
                onClick={this.cancelAppointment}
              ></input>
            </div>
          </div>
        </div> 

        {/* Popup */}
        <div class="popup-overlay" id="term-main">
          <div className="popup-content">
            <div className="modal-popup">
              <span className="close-popup  popup-hide">&times;</span>
              <div className="header border-0"> Terms and Conditions</div>
              <div className="content">
                <div className="col-md-12">
                <p><InnerHTML html={window.sessionStorage.getItem("book_appointment_terms")==="" ? <span  className='text-center d-block'>No, Terms and Condition</span> :window.sessionStorage.getItem("book_appointment_terms") } /></p>
             
                <span><input type="button" value="Accept" className="formButton formButton-sm"  id="AcceptCall"
                /></span>
                <span><input type="button" value="Decline" className="formButton-sm cancelButton" id="decline" /></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="popup-overlay"
          id="alertPopup_app"
          style={{ display: "none" }}
        >
          <div className="popup-content">
            <div className="modal-popup text-center">
              <a
                className="close-popup  popup-hide"
                onClick={() => this.closeAlert()}
              >
                &times;
              </a>
              <p className="mb-2" id="alertPopupTitle_app"></p>
              <p
                className="p-0"
                style={{ fontSize: "85%" }}
                id="alertPopupSubTitle_app"
              ></p>
              <p>
                <button
                  class="formButtonBg mr-2"
                  onClick={() => this.closeAlert()}
                >
                  Done
                </button>
                {/* <button
                  class="formButtonBg cancelButtonBg popup-hide"
                  onClick={() => this.closeAlert()}
                >
                  Cancel
                </button> */}
              </p>
            </div>
          </div>
        </div>
        <Suspense fallback={<div></div>}>
          <section>
            <Footer />
          </section>
        </Suspense>
      </div>
    );
  }
}
