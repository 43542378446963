import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "bootstrap/dist/css/bootstrap.css";
import "./css/style.css";
import "./css/purpleresponsive.css";
import "./css/admin.css";
import "./css/adminresponsive.css";
import { BrowserRouter as Router, Route } from "react-router-dom";
import home from "../src/home/home";
import root from "../src/layout/root";
import AboutUs from "./home/aboutus";
import Contact from "./home/contactus";
import Telehealth from "./home/telehealth";
import BrandedAppsWebsite from "./home/branded-apps-website";
import ClinicManagement from "./home/clinic-management";
import Clients from "../src/home/clients";
import DoctorDetail from "./home/doctor-detail";
import DoctorListMain from "./home/doctor-list-main";
import OnlineDoctors from "./home/online-doctors";
import OnlineDoctorsSub from "./home/online-doctors-sub";
import email from "./patient/email";
import EmailConfirm from "./patient/emailconfirm";
import EmailSuccess from "./patient/emailsuccess";
import Textchat from "./patient/textchat";
import TextchatSuccess from "./patient/textchatsuccess";
import Bookappointment from "./patient/bookappointment";
import BookappointmentSuccess from "./patient/bookappointmentsuccess";
import Chatmessage from "./patient/chatmessage";
import VideocallList from "./patient/videocalllist";
import ChatmessageGroup from "./patient/chatmessagegroup";
import Videocall from "./patient/videocall";
import VideochatSuccess from "./patient/videochatsuccess";
import Appointmentlist from "./patient/appointmentlist";
import Inbox from "./patient/inbox";
import Send from "./patient/send";
import Messgedetail from "./patient/messgedetail";
import Profile from "./patient/profile";
import ProfileEdit from "./patient/profile-edit";
import Dashboard from "./doctor/dashboard";
import Drgallery from "./doctor/drGallery";
import ChangePassword from "./doctor/changepassword";
import WorkTime from "./doctor/worktime";
import Doctorprofile from "./doctor/doctorprofile";
import Docservices from "./doctor/docservices";
import VideoSchedule from "./doctor/videoSchedule";
import Healtharticle from "./doctor/healtharticle";
import Healthblog from "./doctor/healthblog";
import HealthRecord from "./patient/health-record";
import HealthTrackers from "./healthTrackerCalender/health-trackers";
import HealthTrackbabymilestns from "./healthTrackerCalender/health-trackers-babymilestone";
import HealthTrackbabymilestnsdwd from "./healthTrackerCalender/health-trackers-babymilestone-download";
import HealthTrackbabymilestnsdwdpdf from "./healthTrackerCalender/health-trackers-babymilestone-download-pdf";
import HealthTrackimmunisation from "./healthTrackerCalender/health-trackers-immunisation";
import HealthTrackimmunisationdwd from "./healthTrackerCalender/health-trackers-immunisation-download";
import HealthTrackimmunisationdwdpdf from "./healthTrackerCalender/health-trackers-immunisation-download-pdf";
import HealthTrackimmunisationreminders from "./healthTrackerCalender/health-trackers-immunisation-reminders";
//

//  import JoinAsPatient from './IMAHospitals/joinAsPatient'
//
//
//
//

import HealthRecordLogin from "./patient/healthrecordlogin";
import History from "./patient/history";
import DoctorVideocall from "./doctor/videocall";
import DoctorInbox from "./doctor/doctorinbox";
import DoctorSend from "./doctor/doctorsend";
import DoctorMessgedetail from "./doctor/messgedetail";
import DoctorChatmessage from "./doctor/chatmessage";
import DoctorChatmessageGroup from "./doctor/chatmessagegroup";
import DoctorChatHistory from "./doctor/chat-history";
import OldChatMessage from "./patient/archivedMessageChats";
import SamadHome from "./SAMADIVF/home";
import PrescriptionList from "./patient/prescription-list";
import PrescriptionAdd from "./doctor/prescription-add";
import Common from "./home/common";
import HeaderLinks from "./dashboard/headerLinks";
import CommonMenuLinks from "./home/commonMenuLinks";
import PaymentSuccess from "./patient/paymentSuccess";
import AdminDashboard from "./adminDashboard/dashboard";
import Analysis from "./dashboard/analysis";
import ThemeEdit from "./home/themeEdit";
import TermsConditions from "./home/terms-conditions";
import PrivacyPolicy from "./home/privacy-policy";
import DrTermsConditions from "./doctor/terms-conditions";
import InpersonAppointment from "./doctor/inpersonAppointment";
import DoctorPrescription from "./doctor/prescriptionList";
import MapLink from "./Ganga/map";
import TestHouse from "./patient/testHouse";
import BlogList from "./doctor/blog";
import BlogDetails from "./Sabarmathi/blogDetails";
import SiteBlog from "./Sabarmathi/healthBlog";
import Favorate from "./patient/favorate";
/*** pharmacy app screens***/
import Splash from "./pharmacyApp/splash";
import DoctorNetwork from "./pharmacyApp/doctor-network";
import DoctorInvitation from "./pharmacyApp/doctor-invitation";

/*** Doctor Registration***/
import DoctorRegistration from "./doctor/registration";
import doctorRegSuccess from "./doctor/doctorRegSuccess";
import timeComponent from "./doctor/timeComponent";
import registrationErrorPage from "./doctor/registrationErrorPage";
import videoRecordList from "./doctor/videoRecordList";
import reportDoctor from "./doctor/reportDoctor";
import hospitalReports from "./dashboard/hospitalReports";
import privillageReport from "./dashboard/privillageCardDetailsReport";
import Maintenance from "./home/maintenance";
import reportDashboard from "./dashboard/reportDashboard";
import videoaudioReports from "./dashboard/videoaudioReports";
import textChatReports from "./dashboard/textChatReports";
import inPersonReports from "./dashboard/inPersonReports";
import ThemeColor from "./adminDashboard/themeColor";
// import SpeechRecognition from "./home/speechRecognition";
import NewFeeds from "./doctor/newFeedsList";
import NewFeedsListDetails from "./doctor/newFeedsListDetails";
import OpenAppointmentlist from "./patient/openAppointmentList";
import JournalListing from "./doctor/journalListing";
import DoctorRegistrationReport from "./dashboard/doctorRegistrationReport";
import IDABannerSave from "./dashboard/BannerIDASave";
import BannerIDAListing from "./dashboard/BannerIDAListing";
import PharmaNetworkReport from "./dashboard/pharmaNetworkReport";
import DoctorNetworkReport from "./dashboard/doctorNetworkReport";
import AddJournal from "./doctor/addJournal";
import dataMigration from "./adminManagement/dataMigration/dataMigration";
import adApproval from "./adminManagement/pharmaAdAproval/adApproval";
import offerAddPage from "./adminManagement/pharmaAdAproval/offerAddPage";
import addEditofferPage from "./adminManagement/pharmaAdAproval/addEditOfferDetails";
import GenerateQRcodeIda from "./adminManagement/pharmaAdAproval/generateQRcodeIda";
import AddapprovalEdit from "./adminManagement/pharmaAdAproval/adApprovalEdit";
import IdaStoreList from "./adminManagement/pharmaAdAproval/idaStoreList";
import AddEditIdaStore from "./adminManagement/pharmaAdAproval/addEditIdaStore";
import patientTransactionReport from "./dashboard/patientTransactionReport";
import PromocodeDetail from "./hospital/promocodeDetails";

import JoinAsPatient from "./IMAHospitals/joinAsPatient";
import HospitalDetails from "./IMAHospitals/hospitalDetails";
import Grievance from "./IMAHospitals/grievance";
import facilities from "./IMAHospitals/facilities";
import helpLine from "./IMAHospitals/helpLine";
import enroll from "./IMAHospitals/enroll";
import DoctorsList from "./IMAHospitals/doctor-list";

import ImaJoinAsPatient from "./IMAHospitals/joinAsPatient";
import ImaEnroll from "./IMAHospitals/enroll";
import ImaFacilities from "./IMAHospitals/facilities";
import ImaHelpLine from "./IMAHospitals/helpLine";
import Imagrievance from "./IMAHospitals/grievance";
import About from "./IMAHospitals/about-us";

import AnalyticsDashboard from "./analytics/dashboard";
import AnalyticsReport from "./analytics/analyticsReport";
import CategoriesList from "./adminManagement/pharmaAdAproval/categoriesList";
import CategoriesListEdit from "./adminManagement/pharmaAdAproval/categoryListEdit";
import JournalListings from "./adminManagement/pharmaAdAproval/journals";
import JournalListingsAdd from "./adminManagement/pharmaAdAproval/JournalListingsAdd";
// import RedirectFromPulse from "./home/redirectFromPulse";
import { Home } from "react-feather";
import Feedback from "./adminManagement/dataMigration/feedback";
import FAQ from "./home/faq";
import MoreLocations from "./pamba/moreLocations";
import CreatePoll from "./adminManagement/pharmaAdAproval/create-poll";
import PollList from "./adminManagement/pharmaAdAproval/poll-list";
import PollReport from "./adminManagement/pharmaAdAproval/pollReport"
import PollHistory from "./adminManagement/pharmaAdAproval/pollHistory"
ReactDOM.render(
  <Router>
    <Route exact path="/SITEID-:siteId" component={root} />
    <Route path="/" component={home} exact />
    <Route path="/home/aboutus" component={AboutUs} />
    <Route path="/home/contactus" component={Contact} />
    <Route path="/home/telehealth" component={Telehealth} />
    <Route path="/home/branded-apps-website" component={BrandedAppsWebsite} />
    <Route path="/home/clinic-management" component={ClinicManagement} />
    <Route path="/home/clients" component={Clients} />
    <Route path="/home/doctor-detail" component={DoctorDetail} />
    <Route path="/Doctor-:slug" component={DoctorDetail} />
    <Route
      path="/home/doctor-list-main/:serviceType"
      component={DoctorListMain}
      exact
    />
    <Route path="/home/online-doctors" component={OnlineDoctors} />
    <Route path="/home/online-doctors-sub" component={OnlineDoctorsSub} />
    <Route path="/patient/email" component={email} />
    <Route path="/patient/emailconfirm" component={EmailConfirm} />
    <Route path="/patient/emailsuccess" component={EmailSuccess} />
    <Route path="/patient/textchatpayment" component={Textchat} />
    <Route path="/patient/textchatsuccess" component={TextchatSuccess} />
    <Route path="/patient/bookappointment" component={Bookappointment} />
    <Route
      path="/patient/bookappointmentsuccess"
      component={BookappointmentSuccess}
    />
    <Route path="/patient/thankyou" component={PaymentSuccess} />
    <Route path="/chat/chatmessage" component={Chatmessage} />
    <Route path="/chat/archivedMessageChats" component={OldChatMessage} />
    <Route path="/patient/videocalllist" component={VideocallList} />
    <Route path="/chat/chatmessagegroup" component={ChatmessageGroup} />
    <Route path="/patient/videocall" component={Videocall} strict />
    <Route path="/patient/videochatsuccess" component={VideochatSuccess} />
    <Route path="/patient/appointmentlist" component={Appointmentlist} />
    <Route path="/patient/inbox" component={Inbox} />
    <Route path="/patient/send" component={Send} />
    <Route path="/patient/messgedetail" component={Messgedetail} />
    <Route path="/patient/profile" component={Profile} />
    <Route path="/patient/profile-edit" component={ProfileEdit} />
    <Route path="/doctor/dashboard" component={Dashboard} />
    <Route path="/doctor/gallery" component={Drgallery} />
    <Route path="/doctor/changepassword" component={ChangePassword} />
    <Route path="/doctor/worktime" component={WorkTime} />
    <Route path="/doctor/doctorprofile" component={Doctorprofile} />
    <Route path="/doctor/docservices" component={Docservices} />
    <Route path="/doctor/videoSchedule" component={VideoSchedule} />
    <Route path="/doctor/healtharticle" component={Healtharticle} />
    <Route path="/doctor/healthblog/:healthInfoId?" component={Healthblog} />
    <Route path="/patient/health-record" component={HealthRecord} />
    <Route path="/patient/healthrecordlogin" component={HealthRecordLogin} />
    <Route path="/health-trackers" component={HealthTrackers} />
    <Route
      path="/health-trackers-babymilestone"
      component={HealthTrackbabymilestns}
    />
    <Route
      path="/health-trackers-babymilestone-download"
      component={HealthTrackbabymilestnsdwd}
    />
    <Route
      path="/health-trackers-babymilestone-download-pdf"
      component={HealthTrackbabymilestnsdwdpdf}
    />
    <Route
      path="/health-trackers-immunisation"
      component={HealthTrackimmunisation}
    />
    <Route
      path="/health-trackers-immunisation-download"
      component={HealthTrackimmunisationdwd}
    />
    <Route
      path="/health-trackers-immunisation-download-pdf"
      component={HealthTrackimmunisationdwdpdf}
    />
    <Route
      path="/health-trackers-immunisation-reminders"
      component={HealthTrackimmunisationreminders}
    />
    <Route path="/patient/favorate" component={Favorate} />

    <Route path="/patient/history" component={History} />
    <Route path="/doctor/videocall" component={DoctorVideocall} />
    <Route path="/doctor/doctorinbox" component={DoctorInbox} />
    <Route path="/doctor/doctorsend" component={DoctorSend} />
    <Route path="/doctor/messgedetail" component={DoctorMessgedetail} />
    <Route path="/doctor/chatmessage" component={DoctorChatmessage} />
    <Route path="/doctor/chatmessagegroup" component={DoctorChatmessageGroup} />
    <Route path="/doctor/chat-history" component={DoctorChatHistory} />
    <Route path="/samadivf/home" component={SamadHome} />
    <Route path="/patient/prescriptionList" component={PrescriptionList} />
    <Route
      path="/prescriptionAdd-:hospitalId?-:memberId?-:scheduleId?-:patientName"
      component={PrescriptionAdd}
    />
    <Route path="/1-:pageName" component={Common} exact />
    <Route path="/home/themeEdit/:pageName" component={ThemeEdit} />
    <Route path="/dashboard/HeaderLinks" component={HeaderLinks} />
    <Route path="/2-:pageName" component={CommonMenuLinks} />
    <Route path="/admin/dashboard" component={AdminDashboard} />
    <Route path="/dashboard/analysis" component={Analysis} />
    <Route path="/termsConditions" component={TermsConditions} />
    <Route path="/privacyPolicy" component={PrivacyPolicy} exact />
    <Route path="/termsAndConditions" component={DrTermsConditions} />
    <Route path="/doctor/inpersonAppointment" component={InpersonAppointment} />
    <Route
      path="/doctor/prescriptionList-:hospitalId?-:memberId?-:patientName?-:scheduleId"
      component={DoctorPrescription}
    />
    <Route path="/footer/map" component={MapLink} />
    <Route path="/testHouse" component={TestHouse} />
    <Route path="/splash/:splashId?/:networkId" component={Splash} />
    <Route path="/doctorNetwork" component={DoctorNetwork} />
    <Route path="/doctorInvitation" component={DoctorInvitation} />
    <Route
      path="/doctorRegistration/:pmSite?/:doctorId?/:idaId?"
      component={DoctorRegistration}
    />
    <Route path="/doctorRegSuccess" component={doctorRegSuccess} />
    <Route path="/timeComponent" component={timeComponent} />
    <Route path="/registrationErrorPage" component={registrationErrorPage} />
    <Route path="/BlogList" component={BlogList} />
    <Route
      path="/HealthBlog/:blogId?/:shortDescri"
      component={BlogDetails}
      exact
    />
    {/* <Route path="/HealthInfo/:categoryId" component={SiteBlog} /> */}
    <Route path="/HealthInfo" component={SiteBlog} />
    <Route path="/faq-:id" component={FAQ} />
    <Route
      path="/doctor/videoRecordList/:appointmentId?"
      component={videoRecordList}
    />
    <Route path="/doctor/reportDoctor" component={reportDoctor} />
    <Route
      path="/dashboard/hospitalReports/:groupHospitalId"
      component={hospitalReports}
    />
    <Route
      path="/dashboard/privilegeCardReport/:groupHospitalId"
      component={privillageReport}
    />
    <Route path="/maintenance" component={Maintenance} exact />
    <Route
      path="/dashboard/reportDashboard/:groupHospitalId"
      component={reportDashboard}
    />
    <Route
      path="/dashboard/videoaudioReports/:groupHospitalId"
      component={videoaudioReports}
    />
    <Route
      path="/dashboard/textChatReports/:groupHospitalId"
      component={textChatReports}
    />
    <Route
      path="/dashboard/inPersonReports/:groupHospitalId"
      component={inPersonReports}
    />
    <Route
      path="/dashboard/patientTransactionReport/:groupHospitalId"
      component={patientTransactionReport}
    />
    <Route path="/themeColor" component={ThemeColor} />
    {/* <Route path="/speechRecognition"component={SpeechRecognition} />  */}
    <Route path="/newFeedsList" component={NewFeeds} />
    <Route path="/newFeedsListDetails" component={NewFeedsListDetails} />
    <Route
      path="/patient/openAppointmentList"
      component={OpenAppointmentlist}
    />
    <Route path="/journalListing" component={JournalListing} />
    <Route
      path="/dashboard/doctorRegistrationReport/:groupHospitalId"
      component={DoctorRegistrationReport}
    />
    <Route
      path="/dashboard/BannerIDASave:bannerId?"
      component={IDABannerSave}
    />
    <Route path="/dashboard/BannerIDAListing" component={BannerIDAListing} />
    <Route
      path="/dashboard/pharmaNetworkReport/:groupHospitalId"
      component={PharmaNetworkReport}
    />
    <Route
      path="/dashboard/doctorNetworkReport/:groupHospitalId"
      component={DoctorNetworkReport}
    />
    <Route
      path="/adminManagement/adApprovalEdit/:pharmaRepAdsId"
      component={AddapprovalEdit}
    />
    <Route
      path="/adminManagement/pharmadashboardList/addEditIdaStore/:storeId?"
      component={AddEditIdaStore}
    />
    <Route path="/adminManagement/idaStoresList" component={IdaStoreList} />
    <Route
      path="/adminManagement/dataMigration/generateQRcodeIda/:storeId?"
      component={GenerateQRcodeIda}
    />
    <Route path="/adminManagement/categoriesList" component={CategoriesList} />
    <Route
      path="/adminManagement/categoriesListEdit/:storeId?"
      component={CategoriesListEdit}
    />
    <Route path="/adminManagement/offerAddPage" component={offerAddPage} />
    <Route
      path="/adminManagement/addEditofferPage/:storeId?/:offerId?"
      component={addEditofferPage}
    />
    <Route path="/doctor/addJournal/:journalId?" component={AddJournal} />
    <Route path="/admin/dataMigration" component={dataMigration} />
    <Route path="/admin/adApproval" component={adApproval} />

    {/* <Route path="/:token?" component={RedirectFromPulse} exact/> */}
    {/* <Route path="/:token?" component={home} exact/> */}

    <Route path="/hospital/promocodeDetails" component={PromocodeDetail} />

    {/* <Route path="/joinAsPatient" component={JoinAsPatient} /> */}
    <Route path="/hospitalDetails/:hospitalId?" component={HospitalDetails} />
    {/* <Route path="/grievance" component={Grievance} /> */}
    {/* <Route path="/facilities" component={facilities} /> */}
    {/* <Route path="/helpLine" component={helpLine} /> */}
    {/* <Route path="/enroll" component={enroll} />  */}
    <Route path="/doctorsList" component={DoctorsList} />
    <Route path="/joinAsPatient" component={ImaJoinAsPatient} />
    <Route path="/Enroll" component={ImaEnroll} />
    <Route path="/facilities" component={ImaFacilities} />
    <Route path="/helpLine" component={ImaHelpLine} />
    <Route path="/grievance" component={Imagrievance} />
    <Route path="/aboutUs" component={About} />

    <Route path="/analytics/dashboard" component={AnalyticsDashboard} />
    <Route path="/analytics/finalReports" component={AnalyticsReport} />
    <Route path="/5a6uk96q0w-:token" component={home} />
    <Route path="/adminManagement/journalListing" component={JournalListings} />
    <Route
      path="/adminManagement/journalListingAdd/:journalId?"
      component={JournalListingsAdd}
    />
    <Route path="/5a6uk96sph-:token" component={Feedback} />
    <Route path="/moreLocations" component={MoreLocations} />
    <Route path="/adminManagement/create-poll" component={CreatePoll} />
    <Route path="/adminManagement/poll-list" component={PollList}/>
    <Route path ="/adminManagement/poll-report" component={PollReport}></Route>
    <Route path ="/adminManagement/poll-History" component={PollHistory}></Route>
    {/* <Route
      path="/adminManagement/journalDashboard/addJournal/:journalId?"
      component={AddJournal}
    /> */}
  </Router>,
  document.getElementById("root")
);

// npm i @syncfusion/ej2-base@19.4.52
// npm i @syncfusion/ej2-react-inputs@19.4.52
// npm i @syncfusion/ej2-react-richtexteditor@19.4.56
