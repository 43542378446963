import React, { useState, useEffect, Suspense } from "react";
import { awsAPIUrlcommon } from "../../apiService";
import view from "../../image/icons/view.png";
import { Link } from "react-router-dom";
import $ from "jquery";
import Pagination from "react-js-pagination";
import Switch from "react-switch";
import Edit from "../../image/icons/edit.png";
import "../../css/admin.css";
// import Whatsapp from "./whatsapp";
const folderName = window.sessionStorage.getItem("homeName");
const Header = React.lazy(() => import("../../" + folderName + "/header"));
const Footer = React.lazy(() => import("../../" + folderName + "/footer"));
const PollList = () => {
  const [pollListData, setPollListData] = useState([]);
  const [pollListDataDuplicate, setpollListDataDuplicate] = useState([]);
  const [pollListDataPagination, setpollListDataPagination] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [totalPageCount, setTotalPageCount] = useState(0);
  const [pageRange, setPageRange] = useState(10);
  const [itemsPerPage] = useState(10);
  const [pollMessage, setpollMessage] = useState();
  const [pollMessagetext, setpollMessageText] = useState('');
  const [choosedPollDataForNotificatoinEdit, setChoosedPollDataForNotificatoinEdit] = useState('');
  const [choosedPollDataForNotificatoinEditId, setChoosedPollDataForNotificatoinEditId] = useState('');
  useEffect(() => {
    getFullListData()
  }, []);
  const getFullListData = () => {
    $(".loader").show();
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        "functionName": "surveyList",
        "pollName": "",
        "isActive": "",
        "siteId": window.sessionStorage.getItem("siteId"),
        "max": "",
        "offset": ""
      }),
    };
    awsAPIUrlcommon(apiJson).then((data) => {
      if (data.success === "1") {
        const temp = data.result.map((o, i) => ({
          id: i + 1,
          ...o
        }))
        setpollListDataDuplicate(temp.slice(0, 10))
        setPollListData(temp)
        setpollListDataPagination(temp)
        setTotalPageCount(data.totalCount)
        $(".loader").hide();
      } else {
        setpollListDataDuplicate([])
        setPollListData([])
        setpollListDataPagination([])
        setTotalPageCount(0)
        alert("Somethingwent Wrong While Fetching Data")
        $(".loader").hide();
      }
    }).catch((error) => {
      console.error("Error sending notification:", error);
      $(".loader").hide()
      alert("An error occurred while getting Details");
    });
  };
  function formatDate(timestamp) {
    const date = new Date(timestamp);
    const day = date.getUTCDate().toString().padStart(2, '0');
    const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
    const year = date.getUTCFullYear();
    const formattedDate = `${day}-${month}-${year}`;
    return formattedDate;
  }
  const handlePageChange = (pageNumber) => {
    const indexOfLastItem = pageNumber * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = pollListData.slice(indexOfFirstItem, indexOfLastItem);
    setpollListDataDuplicate(currentItems);
    setCurrentPage(pageNumber);
  };
  const sendNotification = (data) => {
    let confirming = window.confirm("Do you really want to send Notification?")
    if (!confirming) {
      return
    }
    $(".loader").show();
    const postData = {
      method: "POST",
      body: JSON.stringify({
        "functionName": "sendNotificationToDoctors",
        "pollId": data,
        "userId": window.sessionStorage.getItem("userId"),
      })
    };
    awsAPIUrlcommon(postData)
      .then((data) => {
        if (data?.success === "1") {
          $(".loader").hide();
          alert("Notification Successfully Delivered");
        } else {
          $(".loader").hide();
          alert("Something went wrong");
        }
      })
      .catch((error) => {
        $(".loader").hide();
        console.error("Error sending notification:", error);
        alert("An error occurred while sending notification");
      });
  };
  const statusChanging = (data, activecase) => {
    const postData = {
      method: "POST",
      body: JSON.stringify({
        "functionName": "activateDeactivatePoll",
        "pollId": data.pollId,
        "isActivePoll": activecase
      })
    };
    awsAPIUrlcommon(postData).then((response) => {
      if (response?.success === "1") {
        getFullListData()
      }
    }).catch((error) => {
      console.error("Error fetching version list:", error);
    });
  };
  const updatePagination = (data) => {
    const pageCount = Math.ceil(data.length / itemsPerPage);
    setPageRange(Math.min(pageCount, 5)); // Update page range with minimum of initialPageRange and actual pageCount
    setTotalPageCount(pageCount);
    setCurrentPage(1); // Reset active page to 1
  };
  const handleInputChangeOnType = (event) => {
    setSearch(event.target.value)
    const filteredResult = pollListData.filter(item => {
      return item.pollName.toLowerCase().includes(event.target.value);
    });
    updatePagination(filteredResult);
    setpollListDataPagination(filteredResult)
    setpollListDataDuplicate(filteredResult.slice(0, itemsPerPage));
  };
  const sendReminderNotificationToDoctors = (data) => {
    let confirming = window.confirm("Do you really want to send Reminder Notification To Doctors?")
    if (!confirming) {
      return
    }
    $(".loader").show();
    const postData = {
      method: "POST",
      body: JSON.stringify({
        "functionName": "sendReminderNotificationToDoctors",
        "pollId": data,
        "userId": window.sessionStorage.getItem("userId"),
      })
    };
    awsAPIUrlcommon(postData)
      .then((data) => {
        if (data.success === "1") {
          $(".loader").hide();
          alert("Reminder Notification Successfully Delivered");
        } else {
          $(".loader").hide();
          alert("Something went wrong");
        }
      })
      .catch((error) => {
        $(".loader").hide();
        console.error("Error sending notification:", error);
        alert("An error occurred while sending notification");
      });
  };
  const handleClickEditMessage = (data) => {
    setpollMessage("")
    setpollMessageText("")
    setChoosedPollDataForNotificatoinEdit(data)
    $('.poll_popupdiv').show();
  }
  const pollNotificationMessage = (e) => {
    setpollMessage(e.target.value);
    if (e.target.value === "pollNotificationMessage") {
      setpollMessageText(choosedPollDataForNotificatoinEdit.pollNotificationMessage)
      setChoosedPollDataForNotificatoinEditId(choosedPollDataForNotificatoinEdit.pollNotificationMessageId)
    } else if (e.target.value === "pollReminderNotificationMessage") {
      setpollMessageText(choosedPollDataForNotificatoinEdit.pollReminderNotificationMessage)
      setChoosedPollDataForNotificatoinEditId(choosedPollDataForNotificatoinEdit.pollReminderNotificationMessageId)
    }
    else if (e.target.value === "pollSuccessMessage") {
      setpollMessageText(choosedPollDataForNotificatoinEdit.pollSuccessMessage)
      setChoosedPollDataForNotificatoinEditId(choosedPollDataForNotificatoinEdit.pollSuccessMessageId)
    }
    else {
      setpollMessageText("")
      setChoosedPollDataForNotificatoinEditId("")
    }
  }
  const cancelClickMessage = () => {
    $('.poll_popupdiv').hide();
    setpollMessage('');
    setpollMessageText('');
  }
  const saveClickMessage = (data) => {
    var confirm = '';
    if (!pollMessage) {
      alert("Please Choose A  Notification Type First")
      return
    }
    if (!pollMessagetext) {
      alert("Please Fill Notification Message")
      return
    }
    if (pollMessage == 'pollNotificationMessage') {
      confirm = 'Do you really want to Customize Poll Notification Message?'
    }
    if (pollMessage == 'pollReminderNotificationMessage') {
      confirm = "Do you really want to Customize Poll Reminder Notification Message?"
    }
    if (pollMessage == 'pollSuccessMessage') {
      confirm = "Do you really want to Customize Poll Success Message?"
    }
    if (pollMessage) {
      let confirming = window.confirm(confirm)
      if (!confirming) {
        return
      }
    }
    if (pollMessage.length != 0) {
      $(".loader").show();
      const postData = {
        method: "POST",
        body: JSON.stringify({
          "functionName": "savePollNotificationDetails",
          "pollId": choosedPollDataForNotificatoinEdit.pollId,
          "createdBy": window.sessionStorage.getItem("userId"),
          "pollNotificationId": choosedPollDataForNotificatoinEditId,
          "type": pollMessage,
          "message": pollMessagetext,
          "currentDateTime": new Date().getTime()
        })
      };
      awsAPIUrlcommon(postData)
        .then((data) => {
          if (data.success === "1") {
            $(".loader").hide();
            $('.poll_popupdiv').hide();
            alert("Successfully Set Your Message");
            getFullListData();
          } else {
            $(".loader").hide();
            alert("Something went wrong");
          }
        })
        .catch((error) => {
          $(".loader").hide();
          console.error("Error sending notification:", error);
          alert("An error occurred while sending notification");
        });
    }
  }
  const handleInputChangeOnTypeMessage = (event) => {
    setpollMessageText(event.target.value)
  }
  return (
    <div className="purpleWrap bg-clr-wthHead">
      <Suspense fallback={<div>Waiting...</div>}>
        <section>
          <Header />
          <div className="loader"></div>
          <div className="container mb-5">
            <div>
              <ul className="breadcrum-adminMangmnt">
                <li>
                  <a href="/admin/dashboard">Dashboard</a>
                </li>
                <li>
                  <a href="" className="current">
                    Poll List
                  </a>
                </li>
              </ul>
            </div>
            <div className="row head-hospMangmntList mb-4">
              <div className="col-md-6">Poll List</div>

              <div className="col-md-4 my-2 my-md-0">
                <input
                  className="form-control"
                  type="text"
                  name="pollName"
                  placeholder="Poll Name"
                  value={search}
                  onChange={handleInputChangeOnType}
                  style={{ height: "35px" }}
                />
              </div>
              <div className="AddNewHspbtnDiv w-auto col-12 col-md-2 pull-right text-right">
                <a href="/adminManagement/create-poll" className="addnewhspbtn mr-3">
                  Add New Poll
                </a>
              </div>
            </div>

            <div className="HosptlMangemntTable">
              {totalPageCount !== 0 && (
                <div className="pagination-qualList pagination-qualListwidth text-right" id="paginationType">
                  <Pagination
                    activePage={currentPage}
                    itemsCountPerPage={itemsPerPage}
                    totalItemsCount={pollListDataPagination.length}
                    pageRangeDisplayed={pageRange} // Adjust as needed
                    onChange={handlePageChange}
                    itemClass="page-item"
                    linkClass="page-link"
                  />
                </div>
              )}
              <div className="table-responsive">
                {pollListDataDuplicate.length ?
                  <table className="table table-bordered-hsp">
                    <thead>
                      <tr>
                        <th className="tablehead-hspmng">Sl No</th>
                        <th className="tablehead-hspmng" style={{ width: "200px" }}>Poll Name</th>
                        <th className="tablehead-hspmng">Description</th>
                        <th className="tablehead-hspmng" style={{ width: "50px" }}>Status</th>
                        <th className="tablehead-hspmng" style={{ width: "140px" }}>Poll Start Time</th>
                        <th className="tablehead-hspmng" style={{ width: "140px" }}>Poll End Time</th>
                        <th className="tablehead-hspmng" style={{ width: "50px" }}></th>
                      </tr>
                    </thead>
                    <tbody>
                      {pollListDataDuplicate.map((data, i) => (
                        <tr>
                          <td>{data.id}</td>
                          <td>{data.pollName}</td>
                          <td>{data.pollDescription}</td>
                          {!data.is_notification_send ?
                            <td><Switch
                              title="Status"
                              className="switch_case"
                              checked={data.isActive === 1}
                              onChange={(checked) => {
                                statusChanging(
                                  data,
                                  data.isActive === 1 ? false : true
                                );
                              }}></Switch></td> : <td>Cannot Change Status of Sent Notification</td>}
                          <td className="nowrap">{formatDate(data.pollStartDate)}</td>
                          <td className="nowrap">{formatDate(data.pollEndDate)}</td>
                          <td className="text-center">
                            <Link
                              to={{
                                pathname: "/adminManagement/create-poll",
                                state: { data: data.pollId },
                              }}
                              // to={
                              //   "/adminManagement/create-poll"}
                              className="mr-2"
                            >
                              <img
                                src={Edit}
                                alt=""
                                width="18"
                                title="Edit"
                                className="img-fluid"
                              />
                            </Link>
                            <Link
                              to={{
                                pathname: "/adminManagement/poll-History",
                                state: { data: data.pollId },
                              }}
                              // to={
                              className=""
                            >
                              <img
                                src={view}
                                alt=""
                                width="18"
                                title="View Poll Details"
                                className="img-fluid"
                              />
                            </Link>
                            {!data.is_notification_send && data.isActive === 1 &&
                              <button
                                type="button"
                                className="formButton-save"
                                title="Send Notification"
                                onClick={(e) => sendNotification(data.pollId)}
                                style={{ fontSize: '12px', padding: '2px 10px' }}
                              >Send Notification</button>}
                            {data.is_notification_send && data.isActive === 1 &&
                              <button
                                type="button"
                                className="formButton-save"
                                title="Send Notification"
                                onClick={(e) => sendReminderNotificationToDoctors(data.pollId)}
                                style={{ fontSize: '12px', padding: '2px 10px' }}
                              >Send Reminder </button>}
                            <Link
                              to={{
                                pathname: "/adminManagement/poll-report",
                                state: { data: data.pollId },
                              }}
                            // to={
                            >
                              <button
                                type="button"
                                className="formButton-save"
                                style={{ fontSize: '12px', padding: '2px 10px' }}
                              >
                                Poll Report
                              </button>
                            </Link>
                            <button
                             type="button"
                             className="formButton-save"
                             title=" Customize Message"
                             style={{ fontSize: '12px', padding: '2px 10px' }}
                              onClick={() => handleClickEditMessage(data)}
                            >
                              Customize 
                            </button>
                          </td>
                        </tr>
                      ))}
                      {/* More table rows */}
                    </tbody>
                  </table>
                  : <div className="text-center">No DataFound</div>}
                {totalPageCount !== 0 && (
                  <div className="pagination-qualList pagination-qualListwidth text-right" id="paginationType">
                    <Pagination
                      activePage={currentPage}
                      itemsCountPerPage={itemsPerPage}
                      totalItemsCount={pollListDataPagination.length}
                      pageRangeDisplayed={pageRange} // Adjust as needed
                      onChange={handlePageChange}
                      itemClass="page-item"
                      linkClass="page-link"
                    />
                  </div>
                )}
              </div>
              <div className="poll_popupdiv" style={{ display: 'none' }}>
                <div className="videocall_div poll_popup" id="shedulePopup">
                  <div className="videocall_header">Message</div>
                  <div className="videocall_body flex-column">
                    <select className="form-control mb-3" value={pollMessage} onChange={(e) => pollNotificationMessage(e)}>
                      <option value="">Select Poll Message</option>
                      <option value="pollNotificationMessage">Send Poll Notification Message</option>
                      <option value="pollReminderNotificationMessage">Send Poll Reminder Notification Message</option>
                      <option value="pollSuccessMessage">Send Poll Success Message</option>
                    </select>
                    <textarea className="form-control" value={pollMessagetext} onChange={handleInputChangeOnTypeMessage}></textarea>
                  </div>
                  <div className="videocall_footer">
                    <button
                      type="button"
                      className="bttn_ok"
                      onClick={(e) => saveClickMessage(e)}
                    >
                      Save
                    </button>
                    <button
                      type="button"
                      className="bttn_ok bg-secondary"
                      onClick={(e) => cancelClickMessage(e)}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {folderName !== null && <Footer />}
        </section>
      </Suspense>
    </div>
  );
}

export default PollList;
